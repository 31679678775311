<template>
  <div class="c-icon-tip">
    <span>
      <Icon
        name="sui_icon_doubt_16px_2"
        color="#959595"
        size="16px"
        @click.stop="showCodTipDialog = true"
      />
    </span>
    <s-dialog 
      :visible.sync="showCodTipDialog" 
      :show-close="true"
      append-to-body
      @open="handleDialogOpen"
      @close="handleDialogClose"
    >
      <img
        class="cod-tip-dialog__logo"
        :src="transformImg({ img: logoUrl })"
      />
      <div class="cod-tip-dialog__title">
        {{ language.SHEIN_KEY_PWA_31717 }}
      </div>
      <div class="cod-tip-dialog__tips">
        <div 
          v-for="(tip, index) in codTips" 
          :key="tip.title" 
          class="cod-tip-dialog__tip"
        >
          <div class="cod-tip-dialog__order">
            {{ index + 1 }}
          </div>
          <div class="cod-tip-dialog__desc">
            <p class="cod-tip-dialog__desc-title">
              {{ tip.title }}
            </p>
            <p 
              v-for="desc in tip.desc" 
              :key="desc"
              class="cod-tip-dialog__desc-content"
            >
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
      <div class="cod-tip-dialog__btn">
        <s-button
          :type="['H44PX', 'primary']"
          @click="showCodTipDialog = false"
        >
          {{ language.SHEIN_KEY_PWA_24647 }}
        </s-button>
      </div>
    </s-dialog>
  </div>
</template>

<script>
import { Dialog as SDialog } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { transformImg } from '@shein/common-function'

export default {
  name: 'CodTips',
  components: {
    SDialog,
    Icon
  },
  emits: ['codTipExpose', 'clickCodTips'],
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    logoUrl: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showCodTipDialog: false,
    }
  },
  computed: {
    codTips() {
      return [
        { title: this.language.SHEIN_KEY_PWA_31718, desc: [this.language.SHEIN_KEY_PWA_31719] },
        { title: this.language.SHEIN_KEY_PWA_31720, desc: [this.language.SHEIN_KEY_PWA_31721, this.language.SHEIN_KEY_PWA_31722] },
        { title: this.language.SHEIN_KEY_PWA_31723, desc: [this.language.SHEIN_KEY_PWA_31724] }
      ]
    }
  },
  methods: {
    handleDialogOpen() {
      this.$emit('codTipExpose')
    },
    handleDialogClose() {
      this.$emit('clickCodTips')
    },
    transformImg
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-icon-tip {
  margin-left: 4/37.5rem;
}

.cod-tip-dialog__logo {
  width: 45/37.5rem;
  height: 30/37.5rem;
  border-radius: 4/37.5rem;
  margin: 8/37.5rem auto;
  display: block;
}
.cod-tip-dialog__title {
  font-size: 14/37.5rem;
  font-weight: 700;
  margin: 12/37.5rem 0;
  line-height: 17/37.5rem;
  color: @sui_color_gray_dark1;
  text-align: center;
  font-family: SF UI Text;
}
.cod-tip-dialog__tip {
  display: flex;
  align-items: center;
  position: relative;
  font-family: SF UI Text;

  &:not(:first-child) {
    padding-top: 6/37.5rem;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: #000;
      left: 11/37.5rem;
      bottom: 50%;
      z-index: -1;
    }
  }

  &:not(:last-child) {
    padding-bottom: 6/37.5rem;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: #000;
      left: 11/37.5rem;
      top: 50%;
      z-index: -1;
    }
  }
}
.cod-tip-dialog__order {
  width: 24/37.5rem;
  height: 24/37.5rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #000;
  font-size: 14/37.5rem;
  font-weight: 700;
  text-align: center;
  line-height: 24/37.5rem;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-right: 12/37.5rem;
}
.cod-tip-dialog__desc-title {
  font-size: 14/37.5rem;
  font-weight: 400;
  line-height: 17/37.5rem;
  color: @sui_color_gray_dark1;
  margin-bottom: 4/37.5rem;
}
.cod-tip-dialog__desc-content {
  font-size: 12/37.5rem;
  line-height: 14/37.5rem;
  font-weight: 400;
  color: @sui_color_gray_dark3;
}
.cod-tip-dialog__btn {
  margin-top: 28/37.5rem;
  display: flex;
  justify-content: center;
  padding-bottom: 20/37.5rem;
  width: 100%;

  button {
    width: 100%;
  }
}
</style>
