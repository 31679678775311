export const googlePayMethods = ['worldpay-googlepay', 'adyen-googlepay', 'nuvei-googlepay', 'routepay-googlepay']

export const scanCodePayMethods = ['adyen-pix', 'adyen-boleto']
export const urlAll = ({
  type = 'place_order',
  children = 'detail'
}) => {
  const urlAll = {
    place_order: {
      // 没二次下单
      detail: '/user/orders/detail/',
      list: '/user/orders/list'
    },
    gift_card: {
      agin: '/giftcard/checkout/again/',
      detail: '/user/orders/giftcard_detail/',
      list: '/user/orders/giftcardlist/'
    },
    buy_xtra: {
      // 没有二次下单
      detail: '/user/orders/virtualDetail/',
      list: '/user/orders/list'
    }
  }
  return urlAll[type][children]
}

