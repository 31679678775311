
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * AfterPay vaulting 选中态
 */
export const AFTERPAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

// 下单地址检测 /v2/User/Address/checkAddress 错误码
export const ADDRESS_ERROR_CODE = [ 401996, 402003, 402004, 402005 ]
