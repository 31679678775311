import { ref, onMounted } from 'vue'
import { abtservice } from 'public/src/services/abt'

export default () => {
  let paymentCustomizeFlow = ref('')
  let applePayCashShow = ref('')
  let codFreeShow = ref('')
  onMounted(async () => {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtResult = await abtservice.getUserAbtResult({ newPosKeys: 'PaymentCustomizeFlow,CodFreeOptimization,ApplepayDropoffOptimization' })
    paymentCustomizeFlow.value = abtResult?.PaymentCustomizeFlow?.param?.PaymentCustomizeShow
    codFreeShow.value = abtResult?.CodFreeOptimization?.param?.CodFreeShow
    applePayCashShow.value = abtResult?.ApplepayDropoffOptimization?.param?.ApplepayCashShow
  })
  return {
    paymentCustomizeFlow,
    codFreeShow,
    applePayCashShow
  }
}
